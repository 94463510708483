<template>
	<section id="faq" class="container aos-init ">
		<div id="accordion" class="row">
			<div class="col-12">
				<h3 class="title_xl title_lightblue mb-4">
					Često postavljana pitanja
				</h3>
			</div>

			<div  class="col-12">
				<div class="border-top pt-3" >
					<a
						id="sta-predstavlja-ova-platforma-i-koji-problem-nameravate-da-resite"
						class="text-left title_m title_lightblue d-block pb-3"
						:class="toogleService(false)"
						data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
						@click="pathFix('sta-predstavlja-ova-platforma-i-koji-problem-nameravate-da-resite')"
					>
						1. Šta predstavlja ova platforma i koji problem nameravate da rešite?
						<figure class="d-inline-block float-right pr-2 pr-lg-4 mb-0">

						<img  class="img-fluid" :src="require(`@/assets/icons/minus.svg`)"
							alt="View more" title="View more" height="17" width="17">
						<img  class="img-fluid" :src="require(`@/assets/icons/plus.svg`)"
							alt="View more" title="View more" height="17" width="17">
						</figure>
					</a>
				</div>

				<div  id="collapseOne" class="collapse" :class="(isAsked === '#sta-predstavlja-ova-platforma-i-koji-problem-nameravate-da-resite') ? 'show' : ''" aria-labelledby="sta-predstavlja-ova-platforma-i-koji-problem-nameravate-da-resite" data-parent="#accordion">
					<div class="card-body pt-0">
						Motivacija za pokretanje platforme je navedena na stranici <router-link to="/about">O platformi</router-link>,
						dok je prvenstveni cilj ovog sajta da bude sveobuhvatna baza srpskih istraživača.
						Ovakva platforma će omogućiti umrežavanje i povećanje inter i intra-disciplinarne
						saradnje među istraživačima koji se nalaze u Srbiji i inostranstvu.
					</div>
				</div>
			</div>

			<div  class="col-12">
				<div class="border-top pt-3" >
					<a
						id="sta-su-glavni-ciljevi-i-kako-ce-se-ostvariti"
						class="text-left title_m title_lightblue d-block pb-3"
						:class="toogleService(false)"
						data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2"
						@click="pathFix('sta-su-glavni-ciljevi-i-kako-ce-se-ostvariti')"
					>
						2. Šta su glavni ciljevi i kako će se ostvariti?
						<figure class="d-inline-block float-right pr-2 pr-lg-4 mb-0">

						<img  class="img-fluid" :src="require(`@/assets/icons/minus.svg`)"
							alt="View more" title="View more" height="17" width="17">
						<img  class="img-fluid" :src="require(`@/assets/icons/plus.svg`)"
							alt="View more" title="View more" height="17" width="17">
						</figure>
					</a>
				</div>

				<div  id="collapse2" class="collapse" :class="(isAsked === '#sta-su-glavni-ciljevi-i-kako-ce-se-ostvariti') ? 'show' : ''" aria-labelledby="sta-su-glavni-ciljevi-i-kako-ce-se-ostvariti" data-parent="#accordion">
					<div class="card-body pt-0">
						Kreiranje javno dostupne i sveobuhvatne baze istraživača, koja istovremeno omogućava
						povezivanje, održavanje edukativnih predavanja i seminara, kao i objavljivanje članaka o
						aktuelnim temama i dešavanjima u oblasti istraživačkog rada.
						<br><br>
						Organizacija mentorskih sesija, pri kojima profesionalno ostvareni istraživači prenose
						znanje i iskustvo na mlađe kolege koji su na početku karijere. Povezivanje univerziteta,
						kompanija, razvojnih centara i instituta, naučno-tehnoloških parkova, investicionih fondova
						i tehnoloških inkubatora. Priređivanje događaja i stvaranje okruženja u kojem istraživači
						mogu da se upoznaju radi razmene ideja i informacija.

					</div>
				</div>
			</div>

			<div  class="col-12">
				<div class="border-top pt-3" >
					<a
						id="kako-se-postaje-clan"
						class="text-left title_m title_lightblue d-block pb-3"
						:class="toogleService(false)"
						data-toggle="collapse" data-target="#kako" aria-expanded="true" aria-controls="kako"
						@click="pathFix('kako-se-postaje-clan')"
					>
						3. Kako se postaje član?
						<figure class="d-inline-block float-right pr-2 pr-lg-4 mb-0">

						<img  class="img-fluid" :src="require(`@/assets/icons/minus.svg`)"
							alt="View more" title="View more" height="17" width="17">
						<img  class="img-fluid" :src="require(`@/assets/icons/plus.svg`)"
							alt="View more" title="View more" height="17" width="17">
						</figure>
					</a>
				</div>

				<div  id="kako" class="collapse" :class="(isAsked === '#kako-se-postaje-clan') ? 'show' : ''" aria-labelledby="kako" data-parent="#accordion">
					<div class="card-body pt-0">
						Članstvo u organizaciji je moguće na osnovu preporuke nekog od članova kroz
						slanje pozivnice sa svog profila.
						<br><br>
						Ukoliko želite da postanete član, neophodno je da vam neko od trenutnih članova pošalje pozivnicu.
						<br><br>
						Pri prijemu pozivnice, potrebno je da popunite pristupni formular, sa Vašim osnovnim podacima,
						detaljima o Vašim oblastima istraživanja i linkovima ka stranicama preko kojih Vas ljudi mogu
						kontaktirati i na kojima se nalaze Vaši radovi.
					</div>
				</div>
			</div>

			<div  class="col-12">
				<div class="border-top pt-3" >
					<a
						id="koji-su-kriterijumi-za-clanstvo"
						class="text-left title_m title_lightblue d-block pb-3"
						:class="toogleService(false)"
						data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4"
						@click="pathFix('koji-su-kriterijumi-za-clanstvo')"
					>
						4. Koji su kriterijumi za članstvo?
						<figure class="d-inline-block float-right pr-2 pr-lg-4 mb-0">

						<img  class="img-fluid" :src="require(`@/assets/icons/minus.svg`)"
							alt="View more" title="View more" height="17" width="17">
						<img  class="img-fluid" :src="require(`@/assets/icons/plus.svg`)"
							alt="View more" title="View more" height="17" width="17">
						</figure>
					</a>
				</div>

				<div  id="collapse4" class="collapse" :class="(isAsked === '#koji-su-kriterijumi-za-clanstvo') ? 'show' : ''" aria-labelledby="koji-su-kriterijumi-za-clanstvo" data-parent="#accordion">
					<div class="card-body pt-0">
						Potrebno je da ispunjavate bar jedan od sledeća tri <router-link to="/about/#kako-da-postanete-clan">kriterijuma</router-link>:
							<br><br>
							<ul>
								<li>
									da ste autor ili koautor bar jednog publikovanog rada;
								</li>
								<li>
									da ste autor ili koautor pronalaska zaštićenog patentnom;
								</li>
								<li>
									da ste doktorirali ili ste trenutno student doktorskih studija.
								</li>
							</ul>
					</div>
				</div>
			</div>

			<div  class="col-12">
				<div class="border-top pt-3" >
					<a
						id="da-li-je-pristup-platformi-besplatan"
						class="text-left title_m title_lightblue d-block pb-3"
						:class="toogleService(false)"
						data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapse5"
						@click="pathFix('da-li-je-pristup-platformi-besplatan')"
					>
						5. Da li je pristup platformi besplatan?
						<figure class="d-inline-block float-right pr-2 pr-lg-4 mb-0">

						<img  class="img-fluid" :src="require(`@/assets/icons/minus.svg`)"
							alt="View more" title="View more" height="17" width="17">
						<img  class="img-fluid" :src="require(`@/assets/icons/plus.svg`)"
							alt="View more" title="View more" height="17" width="17">
						</figure>
					</a>
				</div>

				<div  id="collapse5" class="collapse" :class="(isAsked === '#da-li-je-pristup-platformi-besplatan') ? 'show' : ''" aria-labelledby="da-li-je-pristup-platformi-besplatan" data-parent="#accordion">
					<div class="card-body pt-0">
						Da, kreiranje profila na platformi, kao i pretraživanje sveobuhvatne baze istraživača je potpuno besplatno.
					</div>
				</div>
			</div>

			<div  class="col-12">
				<div class="border-top pt-3" >
					<a
						id="ko-vodi-racuna-o-mojim-podacima-gde-se-skladiste-i-da-li-su-bezbedni"
						class="text-left title_m title_lightblue d-block pb-3"
						:class="toogleService(false)"
						data-toggle="collapse" data-target="#collapse6" aria-expanded="true" aria-controls="collapse6"
						@click="pathFix('ko-vodi-racuna-o-mojim-podacima-gde-se-skladiste-i-da-li-su-bezbedni')"
					>
						6. Ko vodi računa o mojim podacima, gde se skladište i da li su bezbedni?
						<figure class="d-inline-block float-right pr-2 pr-lg-4 mb-0">

						<img  class="img-fluid" :src="require(`@/assets/icons/minus.svg`)"
							alt="View more" title="View more" height="17" width="17">
						<img  class="img-fluid" :src="require(`@/assets/icons/plus.svg`)"
							alt="View more" title="View more" height="17" width="17">
						</figure>
					</a>
				</div>

				<div  id="collapse6" class="collapse" :class="(isAsked === '#ko-vodi-racuna-o-mojim-podacima-gde-se-skladiste-i-da-li-su-bezbedni') ? 'show' : ''" aria-labelledby="ko-vodi-racuna-o-mojim-podacima-gde-se-skladiste-i-da-li-su-bezbedni" data-parent="#accordion">
					<div class="card-body pt-0">
						Udruženje SRPSKI ISTRAŽIVAČI (MB: 28321406) prikuplja, skladišti i obrađuje podatke u skladu sa
						<router-link to="/privacy-policy/">Politikom privatnosti</router-link>  i
						<router-link to="/terms-and-conditions/">Uslovima korišćenja</router-link> platforme.
					</div>
				</div>
			</div>

			<div  class="col-12">
				<div class="border-top pt-3" >
					<a
						id="ko-su-osnivaci-i-kako-se-finansira-platforma"
						class="text-left title_m title_lightblue d-block pb-3"
						:class="toogleService(false)"
						data-toggle="collapse" data-target="#collapse7" aria-expanded="true" aria-controls="collapse7"
						@click="pathFix('ko-su-osnivaci-i-kako-se-finansira-platforma')"
					>
						7. Ko su osnivači  i kako se finansira platforma?
						<figure class="d-inline-block float-right pr-2 pr-lg-4 mb-0">

						<img  class="img-fluid" :src="require(`@/assets/icons/minus.svg`)"
							alt="View more" title="View more" height="17" width="17">
						<img  class="img-fluid" :src="require(`@/assets/icons/plus.svg`)"
							alt="View more" title="View more" height="17" width="17">
						</figure>
					</a>
				</div>

				<div  id="collapse7" class="collapse" :class="(isAsked === '#ko-su-osnivaci-i-kako-se-finansira-platforma') ? 'show' : ''" aria-labelledby="ko-su-osnivaci-i-kako-se-finansira-platforma" data-parent="#accordion">
					<div class="card-body pt-0">
						Udruženje SRPSKI ISTRAŽIVAČI (MB: 28321406), koje stoji iza platforme
						Serbian Researchers je neprofitno, nevladino i apolitično udruženje. <br><br>
						Osnivači su:
						<br><br>
						<ul>
							<li>
								<a href="/researchers/luka" target="_blank" title="Luka Jakovljević">
									Luka Jakovljević
								</a> (doktorand na Univerzitetu u Parizu);
							</li>
							<li>
								<a href="/researchers/nemanja-rakicevic" target="_blank" title="Nemanja Rakićević">
									Nemanja Rakićević
								</a> (doktorand na Imperial koledžu u Londonu) i
							</li>
							<li>
								<a href="https://www.linkedin.com/in/velimirbulatovic/" target="_blank" title="Velimir Bulatović">
									Velimir Bulatović
								</a> (osnivač organizacije <a href="https://www.lazybrain.org/" target="_blank">LazyBrain</a> koja promoviše veštačku inteligenciju).
							</li>
						</ul>
            			Udruženje se finansira iz privatnih sredstava osnivača.
					</div>
				</div>
			</div>

			<div  class="col-12">
				<div class="border-top pt-3" >
					<a
						id="zelim-da-postanem-clan-ali-me-niko-nije-pozvao"
						class="text-left title_m title_lightblue d-block pb-3"
						:class="toogleService(false)"
						data-toggle="collapse" data-target="#collapse8" aria-expanded="true" aria-controls="collapse8"
						@click="pathFix('zelim-da-postanem-clan-ali-me-niko-nije-pozvao')"
					>
						8. Želim da postanem član, ali me niko nije pozvao?
						<figure class="d-inline-block float-right pr-2 pr-lg-4 mb-0">

						<img  class="img-fluid" :src="require(`@/assets/icons/minus.svg`)"
							alt="View more" title="View more" height="17" width="17">
						<img  class="img-fluid" :src="require(`@/assets/icons/plus.svg`)"
							alt="View more" title="View more" height="17" width="17">
						</figure>
					</a>
				</div>

				<div  id="collapse8" class="collapse" :class="(isAsked === '#zelim-da-postanem-clan-ali-me-niko-nije-pozvao') ? 'show' : ''" aria-labelledby="zelim-da-postanem-clan-ali-me-niko-nije-pozvao" data-parent="#accordion">
					<div class="card-body pt-0">
						Velika je verovatnoća da nismo uspeli da obuhvatimo sve naše istraživače, naročito u
						početnom periodu pokretanja platforme, i izvinjavamo se na tome.
						<br><br>
						Naš cilj je da baza bude sveobuhvatna i da obuhvatimo sve naše istrazivače koji
						ispunjavaju kriterijume.
						<br><br>
						Ukoliko ispunjavate uslove i niko iz Vašeg neposrednog okruženja nije trenutno član,
						slobodno nas kontaktirajte preko
						<router-link to="/contact" target="_blank">Kontakt forme</router-link>  uz
						kratak opis vaše pozicije i ukoliko ispunjavate uslove, poslaćemo Vam poziv za članstvo.
					</div>
				</div>
			</div>

			<div  class="col-12">
				<div class="border-top pt-3" >
					<a
						id="sta-da-radim-ukoliko-nemam-licnu-stranicu"
						class="text-left title_m title_lightblue d-block pb-3"
						:class="toogleService(false)"
						data-toggle="collapse" data-target="#collapse9" aria-expanded="true" aria-controls="collapse9"
						@click="pathFix('sta-da-radim-ukoliko-nemam-licnu-stranicu')"
					>
						9. Šta da radim ukoliko nemam <i>"Ličnu stranicu"</i>?
						<figure class="d-inline-block float-right pr-2 pr-lg-4 mb-0">

						<img  class="img-fluid" :src="require(`@/assets/icons/minus.svg`)"
							alt="View more" title="View more" height="17" width="17">
						<img  class="img-fluid" :src="require(`@/assets/icons/plus.svg`)"
							alt="View more" title="View more" height="17" width="17">
						</figure>
					</a>
				</div>

				<div  id="collapse9" class="collapse" :class="(isAsked === '#sta-da-radim-ukoliko-nemam-licnu-stranicu') ? 'show' : ''" aria-labelledby="sta-da-radim-ukoliko-nemam-licnu-stranicu" data-parent="#accordion">
					<div class="card-body pt-0">
						Ovo može biti sajt, LinkedIn profil, GitHub ili bilo koja druga stranica, za koju želite da bude
						javno vidljiva i koja sadrži Vaše kontakt informacije.
						<br><br>
						Ukoliko nemate ni jedno od navedenih, mi bismo Vam preporučili da kreirate
						<a href="https://www.linkedin.com/" target="_blank">LinkedIn profil</a>,
						jer je to najjednostavniji način da Vas zainteresovani kontaktiraju.
					</div>
				</div>
			</div>

			<div  class="col-12">
				<div class="border-top pt-3" >
					<a
						id="sta-da-radim-ukoliko-nemam-stranicu-sa-izlistanim-radovima"
						class="text-left title_m title_lightblue d-block pb-3"
						:class="toogleService(false)"
						data-toggle="collapse" data-target="#collapse10" aria-expanded="true" aria-controls="collapse10"
						@click="pathFix('sta-da-radim-ukoliko-nemam-stranicu-sa-izlistanim-radovima')"
					>
						10. Šta da radim ukoliko nemam <i>"Stranicu sa izlistanim radovima"</i>?
						<figure class="d-inline-block float-right pr-2 pr-lg-4 mb-0">

						<img  class="img-fluid" :src="require(`@/assets/icons/minus.svg`)"
							alt="View more" title="View more" height="17" width="17">
						<img  class="img-fluid" :src="require(`@/assets/icons/plus.svg`)"
							alt="View more" title="View more" height="17" width="17">
						</figure>
					</a>
				</div>

				<div  id="collapse10" class="collapse" :class="(isAsked === '#sta-da-radim-ukoliko-nemam-stranicu-sa-izlistanim-radovima') ? 'show' : ''" aria-labelledby="sta-da-radim-ukoliko-nemam-stranicu-sa-izlistanim-radovima" data-parent="#accordion">
					<div class="card-body pt-0">
						Ovo može biti na primer: Google Scholar, Reseach Gate ili Vaša stranica pri fakultetu sa
						izlistanim radovima.
						<br><br>
						Ukoliko nemate ni jedno od navedenih, mi bismo Vam preporučili da kreirate
						<a href="https://scholar.google.com/" target="_blank">Google Scholar</a>
						nalog, koji automatski prepoznaje i izlistava Vaše objavljene radove.
						<br><br>
						Ukoliko niste u mogućnosti da napravite ni jedno od navedenih, kontaktirajte nas preko
            <router-link to="/contact" target="_blank">Kontakt forme</router-link>.
					</div>
				</div>
			</div>

			<div  class="col-12">
				<div class="border-top pt-3" id="da-li-mozete-da-mi-pomognete-da-napravim-slicnu-platformu">
					<a
						class="text-left title_m title_lightblue d-block pb-3"
						:class="toogleService(false)"
						data-toggle="collapse" data-target="#collapse11" aria-expanded="true" aria-controls="collapse11"
					>
						11. Da li možete da mi pomognete da napravim sličnu platformu?
						<figure class="d-inline-block float-right pr-2 pr-lg-4 mb-0">

						<img  class="img-fluid" :src="require(`@/assets/icons/minus.svg`)"
							alt="View more" title="View more" height="17" width="17">
						<img  class="img-fluid" :src="require(`@/assets/icons/plus.svg`)"
							alt="View more" title="View more" height="17" width="17">
						</figure>
					</a>
				</div>

				<div  id="collapse11" class="collapse" :class="(isAsked === '#da-li-mozete-da-mi-pomognete-da-napravim-slicnu-platformu') ? 'show' : ''" aria-labelledby="da-li-mozete-da-mi-pomognete-da-napravim-slicnu-platformu" data-parent="#accordion">
					<div class="card-body pt-0">
						Naravno.
						Ukoliko ste zainteresovani da napravite platformu sličnu našoj, za Vašu zemlju, alumni klub ili univerzitet, slobodno nas kontaktirajte preko
            <router-link to="/contact" target="_blank">Kontakt forme</router-link>.
					</div>
				</div>
			</div>
		</div>
	</section>


</template>

<script>
export default {
  	name: "Questions",
	computed: {
		isAsked(){
			return window.location.hash;
		}
	},
	methods: {
		toogleService(isVisible) {
			return isVisible ? null : 'collapsed';
		},
		pathFix(id) {
			this.$router.push({ path: `/faq/#`+id });
		}
	}
}
</script>
